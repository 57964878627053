<template>
  <v-app
    class="evalmee_v-app"
    id="evalmee-app"
  >
    <img
      alt="Evalmee"
      class="evalmee_logo"
      src="@/assets/images/logo_evalmee.png"
    >
    <div class="header">
      {{ $t("evalmee.pageNotFound.title") }}
    </div>
    <div class="content">
      {{ $t("evalmee.pageNotFound.content") }}
    </div>
    <v-btn
      href="#/"
      text
      color="primary"
      class="button"
    >
      {{ $t("onboarding.back") }}
    </v-btn>
  </v-app>
</template>

<script>

export default {
  name: "PageNotFound",
}
</script>


<style scoped>
  .evalmee_v-app{
    background: #F8FBFF !important;
  }
  .evalmee_logo {
    width: 30vh;
    margin-left: auto;
    margin-right: auto;
    margin-top: 25vh;
    margin-bottom: 6vh;
  }
  .header {
    margin-left: auto;
    margin-right: auto;
    font-size: 5vh;
  }
  .content {
    margin-left: auto;
    margin-right: auto;
    font-size: 3vh;
    color: grey;
    max-width: 80vh;
    text-align: center;
    padding: 2vh;
  }
  .button {
    width: 10vh;
    margin-left: auto;
    margin-right: auto;
  }
</style>

